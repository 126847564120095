import Image from "next/image";
import React from "react";
import { container_classname, sub_section_heading } from "./constants";

interface AwardsSectionProps {
    subText?: string;
    headingText?: string | JSX.Element;
    sectionClassName?: string;
}

const awardImages = [
    "https://assets.gallabox.com/gb-home/new-landing/awards/best-support.png",
    "https://assets.gallabox.com/gb-home/new-landing/awards/small-business-leader.png",
    "https://assets.gallabox.com/gb-home/new-landing/awards/high-performer.png",
    "https://assets.gallabox.com/gb-home/new-landing/awards/best-roi.png",
];

const AwardsSection: React.FC<AwardsSectionProps> = ({
    subText = "RECOGNITION",
    headingText = (
        <span>
            Highly rated <br />
            by industry leaders
        </span>
    ),
    sectionClassName = "",
}) => {
    return (
        <section className={`lg:pt-14 lg:pb-24 pt-10 pb-20 bg-gray-50 ${sectionClassName}`}>
            <div className={`${container_classname} flex lg:flex-row flex-col lg:gap-8 gap-7 lg:justify-between lg:items-center`}>
                <div className="flex flex-col">
                    <p className="text-base font-semibold" style={{ color: "#3364FA" }}>
                        {subText}
                    </p>
                    <h2 className={`${sub_section_heading}`} style={{ fontFamily: "Degular Display" }}>
                        {headingText}
                    </h2>
                </div>

                <div className="flex flex-col items-center lg:gap-6 gap-4">
                    <Image width={221} height={20} src={"https://assets.gallabox.com/gb-home/new-landing/awards/rating.png"} alt="rating" />
                    <div className="flex lg:gap-6 gap-5">
                        {awardImages.map((imgSource, index) => {
                            return (
                                <div key={index} className="lg:w-full lg:h-28 w-16 h-20">
                                    <Image height={120} width={100} src={imgSource} alt="award-logo" />
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AwardsSection;
