import Image from "next/image";
import React, { CSSProperties } from "react";
import { container_classname, sub_section_heading } from "./constants";

interface SpotlightSectionProps {
    removeHeader?: boolean;
    data?: spotlightContent[];
    textClassName?: string;
    headerText?: string;
    sectionStyle?: CSSProperties;
}
interface spotlightContent {
    image: {
        source: string;
        alt: string;
    };
    description: string;
    title?: string;
}

const defaultSpotlightContent: spotlightContent[] = [
    {
        description: "Prospects are on multiple channels and your team is trying to reach them on all – with low conversion rates.",
        image: {
            source: "https://assets.gallabox.com/gb-home/new-landing/spotlight/down-trend.svg",
            alt: "down-trend",
        },
    },
    {
        description: "Much of your team’s time goes in manual, repetitive tasks instead of focusing more on the core business growth.",
        image: {
            source: "https://assets.gallabox.com/gb-home/new-landing/spotlight/sand-timer.svg",
            alt: "sand-timer",
        },
    },
    {
        description: "Leads and conversations are not tracked 24/7 as they lie scattered across multiple channels.",
        image: {
            source: "https://assets.gallabox.com/gb-home/new-landing/spotlight/broken-chain.svg",
            alt: "broken-chain",
        },
    },
];

const SpotlightSection: React.FC<SpotlightSectionProps> = ({ removeHeader, data = defaultSpotlightContent, textClassName, headerText, sectionStyle }) => {
    return (
        <section className="lg:py-20 py-14" style={{ backgroundColor: "#E6EEFF", ...sectionStyle }}>
            <div className={`${container_classname} flex flex-col gap-12`}>
                {!removeHeader && (
                    <h2 className={`${sub_section_heading} lg:max-w-5xl`} style={{ fontFamily: "Degular Display" }}>
                        {headerText ?? "Calls, emails, and SMS are not enough to drive conversions at scale"}
                    </h2>
                )}
                <div className="flex flex-col lg:flex-row gap-5">
                    {data.map((content, index) => {
                        return (
                            <div className="lg:w-1/3 w-full bg-white rounded-lg p-6 flex flex-col gap-6 items-start" key={`spotlight-section-${index}`}>
                                <Image width={84} height={84} src={content.image.source} alt={content.image.alt} />
                                {content?.title && <p className="text-2xl font-semibold">{content.title}</p>}
                                <p className={`${textClassName ?? "text-xl font-normal"}`}>{content.description}</p>
                            </div>
                        );
                    })}
                </div>
            </div>
        </section>
    );
};

export default SpotlightSection;
