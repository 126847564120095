import Head from "next/head";
import Image from "next/image";
import { FC, PropsWithChildren } from "react";
import { container_classname } from "./constants";

interface LPLayoutProps {
    title: string;
    description: string;
    keywords?: string;
    ogAttributes?: {
        title?: string;
        siteName?: string;
        type?: string;
        description?: string;
        image?: string;
    };

    canonical?: string;
    showSignUpbtn?: boolean;
    showViewPricing?: boolean;
    noIndex?: boolean;
}

const LPLayout: FC<PropsWithChildren<LPLayoutProps>> = (props) => {
    const { children, title, keywords, description, ogAttributes, canonical, showSignUpbtn, showViewPricing, noIndex = true } = props;

    return (
        <div className={"hide-scrollbar  w-screen max-h-screen  overflow-x-hidden font-gb-home-body relative"} style={{ scrollBehavior: "smooth" }}>
            <Head>
                <meta charSet="utf-8" />
                <title>{title}</title>
                <meta name="description" content={description} />

                <meta name="keywords" content={keywords} />
                <meta name="viewport" content="width=device-width, user-scalable=no" />
                <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
                <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
                <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
                <link rel="manifest" href="/site.webmanifest" />

                <link rel="shortcut icon" href="/favicon.ico" />
                <meta name="msapplication-TileColor" content="#3364FB" />
                <meta name="theme-color" content="#3364FB" />

                <meta name="twitter:site" content="@gallabox" />
                <meta name="twitter:card" content="summary_large_image" />

                <meta property="og:title" content={ogAttributes?.title ?? title} />
                <meta property="og:site_name" content={ogAttributes?.siteName ?? "Gallabox"} />

                <meta property="og:type" content={ogAttributes?.type ?? "website"} />
                <meta property="og:description" content={ogAttributes?.description ?? description} />
                <meta property="og:image" content={ogAttributes?.image ?? "https://assets.gallabox.com/gb-home/preview-image.jpg"} />
                <meta property="og:image:url" content={ogAttributes?.image ?? "https://assets.gallabox.com/gb-home/preview-image.jpg"} />
                <meta property="og:image:secure_url" content={ogAttributes?.image ?? "https://assets.gallabox.com/gb-home/preview-image.jpg"} />
                <link rel="preload" href="/fonts/Google_Sans/GoogleSans-Bold.ttf" as="font" crossOrigin="" />
                <link rel="preload" href="/fonts/Google_Sans/GoogleSans-Medium.ttf" as="font" crossOrigin="" />
                <link rel="preload" href="/fonts/Google_Sans/GoogleSans-Regular.ttf" as="font" crossOrigin="" />
                {noIndex && <meta name="robots" content="noindex" />}
                {canonical && <link rel="canonical" href={canonical} />}
            </Head>
            <div className={"bg-white"}>
                <div className="fixed top-0 w-full bg-white z-10" style={{ boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.05)" }}>
                    <div className={`${container_classname} p-4 w-full flex justify-between`}>
                        <Image width={128} height={28} src="/gallabox-logo.svg" alt="Gallabox logo" />
                        {showSignUpbtn && (
                            <a
                                href="https://app.gallabox.com/signup"
                                target="_blank"
                                rel="noreferrer"
                                className="px-4 py-2 text-base font-medium text-white rounded-md hover:no-underline focus:outline-none hover:shadow-lg bg-blue-500 hover:bg-blue-600 transition duration-300 ease-in-out"
                            >
                                Sign up
                            </a>
                        )}
                        {showViewPricing && (
                            <a
                                href="/pricing"
                                target="_blank"
                                rel="noreferrer"
                                className="px-4 py-2 text-base font-medium text-white rounded-md hover:no-underline focus:outline-none hover:shadow-lg bg-blue-500 hover:bg-blue-600 transition duration-300 ease-in-out"
                            >
                                View pricing
                            </a>
                        )}
                    </div>
                </div>

                {children}
                <div className="py-6" style={{ backgroundColor: "#0E1018" }}>
                    <div className={`${container_classname} flex lg:justify-between lg:flex-row flex-col gap-4`}>
                        <span className="text-gray-200  text-sm tracking-wide lg:text-left text-center lg:w-full">
                            © {new Date().getFullYear()} Gallabox Global Inc. All rights reserved.
                        </span>
                        <div className="w-full flex flex-row gap-5 text-sm text-gray-200 lg:justify-end justify-center">
                            <a target="_blank" rel="noreferrer" href="/cookie-policy" data-href="/cookie-policy">
                                Cookie Policy
                            </a>
                            <a target="_blank" rel="noreferrer" href="/privacy-policy" data-href="/privacy-policy">
                                Privacy Policy
                            </a>
                            <a target="_blank" rel="noreferrer" href="/terms" data-href="/terms">
                                Terms & Conditions
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <style jsx>{`
                ::-webkit-scrollbar {
                    width: 8px;
                }

                ::-webkit-scrollbar-track {
                    border-radius: 10px;
                }

                ::-webkit-scrollbar-thumb {
                    border-radius: 10px;
                    background-color: #00000050;
                }
            `}</style>
        </div>
    );
};

export default LPLayout;
