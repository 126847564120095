import React from "react";
import { container_classname } from "./constants";
import CTAButtons from "./CTAButtons";

const CTACard: React.FC = () => {
    return (
        <div className={`${container_classname}  my-16`}>
            <div
                className="flex flex-col lg:flex-row lg:items-center justify-between gap-6 p-8 rounded-lg"
                style={{
                    background: "linear-gradient(265deg, #A1D8FF -7.73%, #396AFF 19.04%, #00238D 61.31%, #000 102.16%)",
                }}
            >
                <div className="text-white">
                    <p className="md:text-5xl text-3xl font-semibold " style={{ fontFamily: "Degular Display" }}>
                        Slash marketing costs by half and <br /> grow revenue 2X
                    </p>
                </div>
                <CTAButtons primaryButtonText="Try for free" variant="secondary-light" style={{ border: "none" }} />
            </div>
        </div>
    );
};

export default CTACard;
