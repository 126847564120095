import Image from "next/image";
import React from "react";
import { container_classname } from "../constants";

export interface FeatureInfo {
    mainTitle: string;
    subtitle?: string;
    imageUrl: string;
    featureInfoList: Array<string>;
    description?: string;
    removeDivider?: boolean;
    imageClassName?: string;
}
const FeatureInfoCard: React.FC<FeatureInfo> = ({ mainTitle, subtitle, imageUrl, featureInfoList, description, removeDivider, imageClassName }) => {
    return (
        <section
            className={`${container_classname} flex md:flex-row flex-col lg:gap-20 gap-8 lg:py-20 py-12 md:border-solid border-t-  border-gray-200 ${
                removeDivider ? "" : "border-b"
            }`}
        >
            <div className="self-center mb-9 md:mb-0 md:w-1/2 w-full">
                <h2 className="md:text-6xl text-3xl font-semibold md:mb-12 mb-6" style={{ fontFamily: "Degular Display" }}>
                    {mainTitle}
                </h2>
                {subtitle && <h5 className="md:text-2xl text-base md:mb-6 mb-4 font-medium">{subtitle}</h5>}
                {featureInfoList.map((featureInfoList, idx) => {
                    return (
                        <div key={idx} className="flex gap-3">
                            <div className="flex-shrink-0 mt-3">
                                <Image src={"https://assets.gallabox.com/gb-home/industry-logos/education/tick-circle.png"} width={24} height={24} alt="tick-circle" />
                            </div>
                            <li className="list-none lg:text-xl text-base">{featureInfoList}</li>
                        </div>
                    );
                })}
                {description && (
                    <div className="flex mt-8">
                        <span className="px-1">*</span>
                        <p className="text-base">{description}</p>
                    </div>
                )}
            </div>
            <Image src={imageUrl} width={600} height={540} alt={mainTitle} className={`md:w-1/2 w-full ${imageClassName}`} />
        </section>
    );
};

export default FeatureInfoCard;
