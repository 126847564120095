import LazyVideo from "@components/LandingPageComponents/LazyVideoComponent";
import AwardsSection from "@components/new-ui/AwardsSection";
import ClientLogos from "@components/new-ui/ClientLogos";
import { container_classname, sub_section_heading } from "@components/new-ui/constants";
import CTAButtons from "@components/new-ui/CTAButtons";
import CTACard from "@components/new-ui/CTACard";
import CTASection from "@components/new-ui/CTASection";
import FeatureInfoCard, { FeatureInfo } from "@components/new-ui/IndustriesSection/FeatureInfoCard";
import StatsInfo, { statsInfoGbColor, StatsInfoProps } from "@components/new-ui/IndustriesSection/StatsInfo";
import IntegrationSection from "@components/new-ui/IntegrationSection";
import LPLayout from "@components/new-ui/LPLayout";
import SpotlightSection from "@components/new-ui/SpotlightSection";
import Image from "next/image";
import React from "react";
import Head from "next/head";

const featureInfo1: FeatureInfo = {
    mainTitle: "Create and run AI chatbots in no time",
    imageUrl: "https://assets.gallabox.com/gb-home/new-landing/us/build-support-automations.png",
    featureInfoList: ["Provide instant answers 24*7 to common questions", "Route complex queries to human agents seamlessly", "Reduce response time and enhance satisfaction"],
};
const featureInfo2: FeatureInfo = {
    mainTitle: "Close deals on WhatsApp like second nature",
    imageUrl: "https://assets.gallabox.com/gb-home/new-landing/us/close-deals-on-wa.png",
    featureInfoList: [
        "Instantly engage new prospects and share product details",
        "Schedule personalized follow-ups automatically without manual intervention",
        "Inform clients about offers and stock updates to close deals quickly",
    ],
};
const featureInfo3: FeatureInfo = {
    mainTitle: "Fuel your business expansion",
    imageUrl: "https://assets.gallabox.com/gb-home/new-landing/us/business-expansion.png",
    featureInfoList: ["Build stronger customer relationships", "Boost repeat bookings", "Unlock upsell and cross-sell opportunities"],
};
const featureInfo4: FeatureInfo = {
    mainTitle: "Build a frictionless billing assistance",
    imageUrl: "https://assets.gallabox.com/gb-home/new-landing/us/billing-assistance.png",
    featureInfoList: ["Auto-send payment reminders", "One-click secure payment links", "Auto-share detailed receipts"],
};
const featureInfo5: FeatureInfo = {
    mainTitle: "Make customer experience your superpower",
    imageUrl: "https://assets.gallabox.com/gb-home/new-landing/us/customer-experience.png",
    featureInfoList: ["Provide 24/7 support with automated responses", "Share real-time service updates", "Enable seamless booking and rescheduling"],
};

// const featureInfo6: FeatureInfo = {
//     mainTitle: "Craft memorable marketing moments",
//     imageUrl: "https://assets.gallabox.com/gb-home/new-landing/us/memorable-marketing-moments.png",
//     featureInfoList: [
//         "Share updates about offers, events, and promotions. *",
//         "Use segmented broadcasts for personalized marketing.",
//         "Run drip campaigns for ongoing engagement.",
//     ],
//     description:
//         "Starting April 1, 2025, Meta will pause all WhatsApp marketing template messages to US phone numbers to focus on improving consumer experience. This is aimed at improving outcomes for businesses.",
// };

const spotlightSectionData = [
    {
        description: "Prospects are on multiple channels and your team is trying to reach them on all – with low conversion rates.",
        image: {
            source: "/nodes.svg",
            alt: "nodes",
        },
    },
    {
        description: "Open rates for emails/SMS are low; phone calling is tedious and time-consuming.",
        image: {
            source: "https://assets.gallabox.com/gb-home/new-landing/spotlight/sand-timer.svg",
            alt: "sand-timer",
        },
    },
    {
        description: "Much of your team’s time goes in manual, repetitive tasks instead of focusing more on the core business growth.",
        image: {
            source: "https://assets.gallabox.com/gb-home/new-landing/spotlight/down-trend.svg",
            alt: "down-trend",
        },
    },
];

const statsInfoData: Array<StatsInfoProps> = [
    { percentage: "50%", label: "Reduction in marketing costs" },
    { percentage: "2X", label: "Growth in business" },
    { percentage: "Priceless!", label: "Time back in your schedule" },
];

const WhatsappAutomation: React.FC = () => {
    return (
        <LPLayout title="" description="" ogAttributes={{ image: "" }} showViewPricing noIndex={false}>
            <Head>
                <script src="https://fast.wistia.com/embed/medias/730lmkyzty.jsonp" async></script>
                <script src="https://fast.wistia.com/assets/external/E-v1.js" async></script>
                <link rel="alternate" hrefLang="en" href="https://gallabox.com/" />
                <link rel="alternate" hrefLang="en-us" href="https://gallabox.com/us" />
                <link rel="alternate" hrefLang="ar" href="https://gallabox.com/ar" />
                <link rel="alternate" hrefLang="x-default" href="https://gallabox.com/" />

                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "Organization",
                        legalName: "Gallabox",
                        description:
                            "Gallabox is an Indian SaaS product that helps SMBs automate their customer conversations on WhatsApp. It’s best known for their drag & drop WhatsApp chatbot builder.",
                        url: "https://gallabox.com",
                        logo: "https://gallabox.com/gallabox-logo.svg",
                        email: "team@gallabox.com",
                        parentOrganization: "MangoLeap",
                        sameAs: [
                            "https://www.facebook.com/gallaboxofficial",
                            "https://twitter.com/gallabox",
                            "https://www.instagram.com/gallabox",
                            "https://www.linkedin.com/company/gallabox/",
                            "https://www.youtube.com/c/Gallabox/videos",
                        ],
                        foundingDate: "2021",
                        founders: [
                            {
                                "@type": "Person",
                                name: "Karthik Jagannathan",
                            },
                            {
                                "@type": "Person",
                                name: "Yogesh Narayanan",
                            },
                            {
                                "@type": "Person",
                                name: "Yathindhar Panchanathan",
                            },
                        ],
                        headquarters: {
                            "@type": "Place",
                            address: {
                                "@type": "PostalAddress",
                                addressLocality: "Chennai",
                                addressCountry: {
                                    "@type": "Country",
                                    name: "India",
                                },
                            },
                        },
                        CEO: {
                            "@type": "Person",
                            name: "Karthik Jagannathan",
                        },
                    })}
                </script>
            </Head>
            <section className="lg:pt-16 pt-10">
                <header className="lg:max-w-7xl lg:mx-auto px-5 w-full flex flex-col lg:py-16 pb-16 gap-16">
                    <div className="flex flex-col lg:flex-row lg:py-5 lg:gap-0 gap-8">
                        <div className="lg:w-1/2 w-full flex flex-col justify-start lg:gap-0 gap-4 mt-14 lg:mt-0">
                            <Image src={"https://assets.gallabox.com/gb-home/new-landing/footer/meta-business-partner-dark.png"} alt="" width={285} height={18} />
                            <h1 className="lg:text-new-h1 text-5xl font-bold lg:mb-6 lg:mt-5" style={{ fontFamily: "Degular Variable" }}>
                                Go from <br /> conversations to <br /> conversions—all <br /> on WhatsApp
                            </h1>
                            <p className="text-xl font-normal lg:mb-8 leading-8 lg:w-3/4">
                                Give your business the AI chatbot advantage that was only available for Fortune 500 companies. Boost revenues and free up your time — at a fraction
                                of the cost.
                            </p>
                            <CTAButtons primaryButtonText="Try for free" />
                            <div className="flex items-center lg:gap-5 gap-4 lg:mt-5 mt-0">
                                <div className="flex items-center gap-2">
                                    <Image width={24} height={24} src="https://assets.gallabox.com/gb-home/new-landing/hero-section/g2-logo.svg" alt="g2 logo" />
                                    <p className="text-sm">Rated 4.6/5 on G2</p>
                                </div>
                                <p className="text-sm">|</p>
                                <p className="text-sm">No credit card required</p>
                            </div>
                        </div>
                        <div className="lg:w-1/2 w-full flex lg:justify-end justify-center">
                            <div className="rounded-lg overflow-hidden">
                                <LazyVideo
                                    src={"https://assets.gallabox.com/gb-home/new-landing/hero-section/hero-right-section.webm"}
                                    type={"video/webm"}
                                    poster="https://assets.gallabox.com/gb-home/new-landing/hero-section/poster.png"
                                    className="rounded-lg object-contain"
                                    style={{ maxHeight: "550px" }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="w-full">
                        <h3 className="text-base text-center font-normal py-2">Trusted by 10,000+ businesses</h3>
                        <ClientLogos />
                    </div>
                </header>
            </section>
            <SpotlightSection removeHeader data={spotlightSectionData} textClassName="text-lg" />
            <section className={`${container_classname} my-20`}>
                <h2 className="lg:text-6xl text-5xl font-semibold" style={{ fontFamily: "Degular Display" }}>
                    Here&apos;s the missing piece you&apos;ve been looking for!
                </h2>
                <p className="text-xl mt-5" style={{ fontFamily: "Inter" }}>
                    Automating your customer conversations using AI chatbots is the only solution you need to convert clicks to conversations and leads to deals. And, do this on
                    WhatsApp, the world&apos;s go-to platform.
                </p>

                <h3 className="text-4xl mt-10" style={{ fontFamily: "Degular Display" }}>
                    But first, what is customer conversation automation?
                </h3>
                <p className="text-xl mt-5" style={{ fontFamily: "Inter" }}>
                    Customer conversation automation is all about replacing manual responses with AI chatbots. These chatbots can be trained to respond to and chat with your
                    prospects and customers. Not just conversations, the automation also helps drive critical business outcomes including appointments, registrations, meetings, and
                    orders.
                </p>

                <h3 className="text-4xl mt-10" style={{ fontFamily: "Degular Display" }}>
                    Okay, but why WhatsApp?
                </h3>
                <Image
                    src="https://assets.gallabox.com/gb-home/new-landing/us/how-business-drive-growth.svg"
                    alt="illustration of why whatsapp automation needed"
                    width={1240}
                    height={550}
                    className="my-5 lg:block hidden"
                />

                <Image
                    src="https://assets.gallabox.com/gb-home/new-landing/us/why-whatsapp-automation-mobile.png"
                    alt="illustration of why whatsapp automation needed"
                    width={1240}
                    height={550}
                    className="my-5 lg:hidden"
                />
            </section>
            <StatsInfo statsInfo={statsInfoData} statsInfoText="With this, you can experience:" backgroundColor={statsInfoGbColor} />
            <div className="mt-10">
                <h2 className={`${sub_section_heading} text-center`} style={{ fontFamily: "Degular Display" }}>
                    What business outcomes are we talking about?
                </h2>
                <FeatureInfoCard {...featureInfo1} removeDivider />
                <FeatureInfoCard {...featureInfo2} removeDivider />
                <FeatureInfoCard {...featureInfo3} removeDivider />
                <FeatureInfoCard {...featureInfo4} removeDivider />
                <FeatureInfoCard {...featureInfo5} removeDivider />
            </div>

            <section className={"py-14 md:py-20 bg-alice-blue md:bg-white"}>
                <div className={`${container_classname}`}>
                    <h2 className={`${sub_section_heading} text-center`} style={{ fontFamily: "Degular Display" }}>
                        How do these business outcomes drive growth?
                    </h2>
                    <Image
                        src="https://assets.gallabox.com/gb-home/new-landing/us/how-business-drive-growth-mobile.svg"
                        alt="how business drive growth illustrated"
                        width={1242}
                        height={800}
                        className="mt-5 lg:hidden"
                    />
                    <Image
                        src="https://assets.gallabox.com/gb-home/new-landing/us/why-whatsapp-automation.svg"
                        alt="how business drive growth illustrated"
                        width={1242}
                        height={800}
                        className="mt-5 lg:block hidden"
                    />
                </div>
            </section>

            <CTACard />
            <IntegrationSection isLight />
            <AwardsSection sectionClassName="bg-ghost-white" />
            <CTASection title="Turn connections into conversions" disableCTA={true} />
        </LPLayout>
    );
};

export default WhatsappAutomation;
