import React, { PropsWithChildren } from "react";
import { IconType } from "react-icons";
import { LuArrowUpRight } from "react-icons/lu";

const baseButtonClass = "cursor-pointer flex gap-2 justify-center rounded";

type Size = "lg" | "md";
type IconSize = "sm" | "md";
type Variant = "primary-light" | "secondary-light" | "primary-dark" | "secondary-dark";

type AnchorHTMLAttributes = React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>;
interface GBButtonProps extends AnchorHTMLAttributes {
    size?: Size;
    variant?: Variant;
    leftIcon?: IconType;
    rightIcon?: IconType;
    iconSize?: IconSize;
}

const iconSizes: Record<IconSize, string> = {
    sm: "20px",
    md: "24px",
};

const sizes: Record<Size, string> = {
    lg: "py-4 px-6 text-lg font-semibold leading-6",
    md: "py-2 px-3 text-base leading-6",
};
const variants: Record<Variant, AnchorHTMLAttributes["style"]> = {
    "primary-light": { backgroundColor: "#3364FA", color: "white" },
    "primary-dark": { backgroundColor: "black", color: "white" },
    "secondary-light": { backgroundColor: "white", color: "black", border: "1px solid #000" },
    "secondary-dark": { backgroundColor: "white", color: "black" },
};

export const GBButton: React.FC<PropsWithChildren<GBButtonProps>> = ({ children, variant = "primary-light", size = "lg", style = {}, className = "", ...rest }) => {
    return (
        <a className={`${sizes[size]} ${baseButtonClass} ${className}`} style={{ ...variants[variant], textDecoration: "none", ...style }} {...rest}>
            {children}
        </a>
    );
};

export const GBButtonWithIcon: React.FC<PropsWithChildren<GBButtonProps>> = ({
    children,
    variant = "primary-light",
    size = "lg",
    style = {},
    className = "",
    leftIcon: LeftIcon,
    rightIcon: RightIcon,
    iconSize = "md",
    ...rest
}) => {
    return (
        <a className={`${sizes[size]} ${baseButtonClass} ${className}`} style={{ ...variants[variant], textDecoration: "none", ...style }} {...rest}>
            {LeftIcon && <LeftIcon fontSize={iconSizes[iconSize]} />}
            {children}
            {RightIcon && <RightIcon fontSize={iconSizes[iconSize]} />}
        </a>
    );
};

interface CTAButtonsProps extends GBButtonProps {
    primaryButtonText?: string;
    secondaryButtonText?: string;
}

const CTAButtons: React.FC<CTAButtonsProps> = (props) => {
    const { primaryButtonText = "Signup for free", ...restProps } = props;

    return (
        <div className="flex flex-col lg:flex-row lg:gap-8 gap-6 relative">
            <GBButtonWithIcon href="https://app.gallabox.com/signup" target="_blank" rightIcon={LuArrowUpRight} {...restProps}>
                {primaryButtonText}
            </GBButtonWithIcon>
            {/* <DesktopWebinarModal isStandalonePage={false}>
                <GBButtonWithIcon variant="secondary-light" rightIcon={LuArrowUpRight} id="book-demo">
                    {secondaryButtonText}
                </GBButtonWithIcon>
            </DesktopWebinarModal> */}
        </div>
    );
};

interface CTADarkButtonsProps {
    primaryButtonText?: string;
    secondaryButtonText?: string;
}

export const CTADarkButtons: React.FC<CTADarkButtonsProps> = (props) => {
    const { primaryButtonText = "Signup for free" } = props;
    return (
        <div className="flex flex-col lg:flex-row lg:gap-8 gap-6">
            <GBButtonWithIcon variant="primary-dark" href="https://app.gallabox.com/signup" target="_blank" rightIcon={LuArrowUpRight}>
                {primaryButtonText}
            </GBButtonWithIcon>
        </div>
    );
};

export default CTAButtons;
